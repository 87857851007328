












































































































































import { PaginatedList } from "@/api/models/common/paginatedList";
import { Fournisseur } from "@/api/models/fournisseurs/fournisseur";
import {
  BButton,
  BCard,
  BCol,
  BDropdown,
  BFormSelect,
  BFormCheckbox,
  BDropdownItem,
  BFormInput,
  BPagination,
  BRow,
  BTable,
  BAlert,
} from "bootstrap-vue";
import { Component, Vue, Watch } from "vue-property-decorator";
import CreateFournisseur from "./Create.vue";

@Component({
  components: {
    BAlert,
    BCard,
    BRow,
    BCol,
    BButton,
    BFormInput,
    BFormCheckbox,
    BTable,
    BPagination,
    BDropdown,
    BDropdownItem,
    BFormSelect,
    CreateFournisseur,
  },
})
export default class ListFournisseurs extends Vue {
  perPage = 10;
  currentPage = 1;
  perPageOptions = [2, 5, 10, 25, 50, 100];
  pertinentOnly = false;
  searchQuery = "";
  timeout: any = null;
  isSortDirDesc = false;
  sortBy = "nom";
  isAddNewFournisseurSidebarActive = false;

  tableColumns = [
    { key: "nom", sortable: true },
    { key: "ville", sortable: true },
    { key: "telephone1", sortable: true },
    { key: "telephone2", sortable: true },
    { key: "pertinentLabel", sortable: true, label : "Pertinent" }
  ];
  paginatedListFournisseurs = new PaginatedList<Fournisseur>();

  async created() {
    await this.loadListFournisseur({
      pageNumber: this.currentPage,
      pageSize: this.perPage,
      orderBy: this.sortBy,
      orderByDescending: this.isSortDirDesc,
      search: this.searchQuery,
      pertinentOnly: this.pertinentOnly
    });
  }

  get paginationFrom() {
    return (
      this.perPage * (this.currentPage - 1) +
      (this.paginatedListFournisseurs.items.length ? 1 : 0)
    );
  }
  get paginationTo() {
    return (
      this.perPage * (this.currentPage - 1) +
      this.paginatedListFournisseurs.items.length
    );
  }

  @Watch("currentPage")
  @Watch("perPage")
  @Watch("pertinentOnly")
  async paginationChanged() {
    await this.loadListFournisseur({
      pageNumber: this.currentPage,
      pageSize: this.perPage,
      search: this.searchQuery,
      pertinentOnly: this.pertinentOnly
    });
  }

  @Watch('searchQuery')
  @Watch("sortBy")
  @Watch("isSortDirDesc")
  @Watch("pertinentOnly")
  async searchFournisseur() {
    if(this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(async () => {
      await this.loadListFournisseur({
        pageNumber: this.currentPage,
        pageSize: this.perPage,
        orderBy: this.sortBy,
        orderByDescending: this.isSortDirDesc,
        search: this.searchQuery,
        pertinentOnly: this.pertinentOnly,
      })
    }, 1000);
  }

  updateSort(params: any) {
    this.sortBy = params.sortBy;
    params = {
      pageNumber: this.currentPage,
      pageSize: this.perPage,
      orderBy: this.sortBy,
      orderByDescending: this.isSortDirDesc,
      pertinentOnly: this.pertinentOnly
    };
    this.isSortDirDesc = !this.isSortDirDesc;
    this.loadListFournisseur(params);
  }

  async loadListFournisseur(params: any) {
    if (!params || params?.reset) {
      this.paginatedListFournisseurs = new PaginatedList<Fournisseur>();
    }

    await this.$http.myressif.fournisseurs
      .paginatedList(
        params?.pageNumber ?? 1,
        params?.pageSize ?? 10,
        params?.orderBy,
        params?.orderByDescending,
        params?.search ?? "",
        params?.pertinentOnly,
      )
      .then((response: PaginatedList<Fournisseur>) => {
        this.paginatedListFournisseurs = response;
      });
  }

  onRowSelected(record: any) {
    this.$router.push({
      name: "details-fournisseur",
      params: { id: record.id },
    });
  }
}
