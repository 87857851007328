
















































































































































import { BSidebar,
  BForm,
  BButton,
  BCard,
  BCol,
  BFormCheckbox,
  BFormRadio,
  BDropdown,
  BFormGroup,
  BDropdownItem,
  BFormInput,
  BPagination,
  BRow,
  BTable,
  BSpinner,
} from 'bootstrap-vue';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import { FournisseurCreateModel } from "@/api/models/fournisseurs/fournisseur"
import SearchableVueSelect from "@/components/selects/SearchableVueSelect.vue";
import { PaginatedList } from '@/api/models/common/paginatedList';
import { errorAlert, successAlert } from '@/libs/sweetAlerts/alerts';

@Component({
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BFormInput,
    BTable,
    BPagination,
    BDropdown,
    BDropdownItem,
    BSidebar, 
    BForm,
    BFormGroup,
    BFormCheckbox,
    BFormRadio,
    BSpinner,
    ValidationProvider,
    ValidationObserver,
    SearchableVueSelect
  },
  name: "create-fournisseur"
})
export default class CreateFournisseur extends Vue {
  @Prop({ required: true, type: Boolean }) isAddNewFournisseurSidebarActive!: boolean
  loading = false

  required = required;
  
  newFournisseur: FournisseurCreateModel = {}
  listSecteursActivite = new PaginatedList<any>()

  resetForm() {
    this.newFournisseur = {}
  }

  async submit() {
    this.loading = true
    await this.$http.myressif.fournisseurs.post(this.newFournisseur)
      .then(
        async (response: any) => {
            successAlert.fire({
              timer: 3500,
              title: "Création d'un fournisseur",
              text: "Fournisseur créé dans MyRessif avec succès",
            });
          },     
        (error: any) => {
          if (
            error?.response?.data?.errors &&
            Object.keys(error.response.data.errors).length
          ) {
            errorAlert.fire({
              text: error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
              ],
            });
          } else {
            errorAlert.fire({
              text: error?.response?.data?.detail ?? error.message,
            });
          }
        }
      )
      .finally(() => {
        this.$emit('refetch-data')
        this.$emit('update:is-add-new-fournisseur-sidebar-active', false)
        this.loading = false
      }); ;
  } 
}
